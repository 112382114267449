
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonText,
  IonButton,
  onIonViewWillEnter,
  IonIcon,
  IonBadge,
  IonImg,
  IonThumbnail,
  modalController,
  IonLoading,
} from "@ionic/vue";
import { checkmark, pencil, mail, addCircle } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import apiInterventi from "../services/interventi";
import { openToast } from "../services/toast";

import moment from "moment";

import ImagesModal from "../components/ImagesModal.vue";

export default {
  name: "Tab2",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonText,
    IonButton,
    IonIcon,
    IonBadge,
    IonImg,
    IonThumbnail,
    IonLoading,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    let id = route.params.id as string; //tickets_reports_id by route params
    const loading = ref(false);
    const intervento = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    const customerNotSigned = ref(false);
    const operatorNotSigned = ref(false);
    const sendCustomerMail = ref(false);

    const materiali_usati = ref([]);
    const materiali_ritirati = ref([]);
    const intervento_immagini = ref([]);

    //loading mail
    const loadingMail = ref(false);
    const setOpen = (state: boolean) => (loading.value = state);

    /*
     *
     * Update tickets_reports_id with the newly created id
     *
     */
    function updateId() {
      id = route.params.id as string;
    }

    /**
     *
     * Set intevento images src attribute
     *
     */
    function setImage(imageSrc) {
      if (imageSrc) {
        return `https://crm.sicurity.it/uploads/${imageSrc}`;
      }
    }

    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    function riferimentoCliente(intervento) {
      if (intervento.customers_company) {
        return `${intervento.customers_company}`;
      } else {
        return `${intervento.customers_name} ${intervento.customers_last_name}`;
      }
    }

    /**
     *
     * Print Yes or No based on invia_mail value
     *
     */
    const invioMail = computed(() => {
      return (intervento) => {
        if (intervento.interventi_invia_mail === "1") {
          return "Sì";
        } else {
          return "No";
        }
      };
    });

    const checkImpianto = computed(() => {
      return (impianto) => {
        if (impianto.projects_codice) {
          return `${impianto.projects_codice} - ${impianto.customers_address}`;
        } else {
          return `${impianto.customers_address}`;
        }
      };
    });

    /**
     *
     * Set correct background for intervento status
     *
     */
    const statoIntervento = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //terminato
          className = "success";
        } else if (statusId == 2) {
          //da terminare
          className = "danger";
        }
        return className;
      };
    });

    /**
     *
     * Return '-' if field is null
     *
     */
    function checkField(field) {
      //console.log(field);
      if (field == null || field == "") {
        return "-";
      } else {
        return field;
      }
    }

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    /**
     *
     * Routing for actions
     *
     */
    const caricaFoto = () => {
      router.push({
        name: "InterventoPhotos",
      });
    };

    const firmaCliente = () => {
      router.push({
        name: "FirmaCliente",
      });
    };

    const firmaOperatore = () => {
      router.push({
        name: "FirmaOperatore",
      });
    };

    /**
     * Send email to customer with PDF created with intervento details
     */
    function sendMail() {
      loadingMail.value = true;

      const idIntervento = id;
      const data = new FormData();
      data.append("tickets_reports_invia_mail", "1");

      apiInterventi
        .sendCustomerMail(data, idIntervento)
        .then((response) => {
          openToast(
            "Verrà inviata una email al cliente con i dettagli dell'intervento",
            "success"
          );
          sendCustomerMail.value = true;
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la modifica dell'intervento", "danger");
          //console.error(error);
        })
        .finally(() => {
          loadingMail.value = false;
          //console.log("fine modifica intervento per email cliente");
        });
    }

    function getIntervento() {
      loading.value = true;
      apiInterventi
        .getInterventoDetail(id, userID)
        .then((response) => {
          intervento.value = response;
          //console.log(response);

          //ASSEGNO MATERIALI USATI
          if (intervento.value["tickets_reports_used_equipments"]) {
            materiali_usati.value = JSON.parse(
              intervento.value["tickets_reports_used_equipments"]
            );
          }
          //ASSEGNO MATERIALI RITIRATI
          if (intervento.value["tickets_reports_materiali_ritirati"]) {
            materiali_ritirati.value = JSON.parse(
              intervento.value["tickets_reports_materiali_ritirati"]
            );
          }
          //ASSEGNO IMMAGINI INTERVENTO
          if (intervento.value["tickets_reports_immagini"]) {
            //console.log(intervento.value["tickets_reports_immagini"]);
            for (const [key, value] of Object.entries(
              intervento.value["tickets_reports_immagini"]
            )) {
              //console.log(intervento_immagini.value);
              //console.log(`${key}: ${value}`);
              intervento_immagini.value = [value, ...intervento_immagini.value];
            }
          }
          //FLAG PER DECIDERE SE MOSTRARE BUTTON PER FIRMARE/INVIARE MAIL OPPURE NO (= azioni già compiute)
          if (intervento.value["tickets_reports_firma_cliente_b64"]) {
            customerNotSigned.value = true;
          }
          if (intervento.value["tickets_reports_firma_operatore_b64"]) {
            operatorNotSigned.value = true;
          }
          if (intervento.value["tickets_reports_invia_mail"] === "0") {
            sendCustomerMail.value = false;
          } else {
            sendCustomerMail.value = true;
          }
        })
        .catch((error) => {
          //error.value = true;
          console.error(error);
          openToast("Errore durante la richiesta dell'intervento", "danger");
        })
        .finally(() => {
          loading.value = false;
        });
    }

    /**
     *
     * Open modal to add images in intervento
     *
     */
    async function modalPhoto() {
      const modal = await modalController.create({
        component: ImagesModal, //Component to render inside ionic modal
        componentProps: {
          intervento_id: id,
        },
      });
      modal.onDidDismiss().then((detail) => {
        //console.log(detail);
        if (detail.data != undefined) {
          console.log(detail.data);
          openToast(detail.data.txt, "success");

          const editedIntervento = detail.data.intervento;
          intervento_immagini.value.length = 0;
          if (editedIntervento) {
            for (const [key, value] of Object.entries(
              editedIntervento["tickets_reports_immagini"]
            )) {
              //console.log(`${key}: ${value}`);
              intervento_immagini.value = [value, ...intervento_immagini.value];
            }
          }
        }
      });
      return modal.present();
    }

    onIonViewWillEnter(() => {
      updateId();
      getIntervento();
    });

    onMounted(() => {
      //localInterventi = JSON.parse(localStorage.getItem("interventi"));
      //GET INTERVENTI DATA
      //getIntervento();
      /*intervento.value = localInterventi.find(
        (intervento) => intervento.interventi_id === id
      );*/
    });

    return {
      loading,
      intervento,
      riferimentoCliente,
      dateFormat,
      checkField,
      checkImpianto,
      invioMail,
      //nuovi dopo aver aggiunti pulsanti in dett. intervento
      firmaCliente,
      firmaOperatore,
      //Flag for disabling button
      customerNotSigned,
      operatorNotSigned,
      checkmark,
      //invio mail al cliente
      sendMail,
      sendCustomerMail,
      //Modale per foto
      modalPhoto,
      caricaFoto,
      /* New field for figma layout */
      statoIntervento,
      materiali_usati,
      materiali_ritirati,
      intervento_immagini,
      setImage,
      pencil,
      mail,
      addCircle,
      //loading invio mail
      setOpen,
      loadingMail,
    };
  },
};
